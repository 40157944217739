@import "_colors";
@import "_media";

$topPadding: 46px;
$bottomPadding: 30px;
$leftRightPadding: 20px;
$headerTopDistance: 8px;
$headerSidesDistance: 18px;

.notDisplayed {
    display: none !important;
}

.notVisible {
    visibility: hidden !important;
}

@mixin flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.displayFlex {
    @include flex;
}

@mixin flexDirectionRow {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    flex-direction: row;
}

.flexDirectionRow {
    @include flexDirectionRow;
}

@mixin flexDirectionColumn {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    flex-direction: column;
}

.flexDirectionColumn {
    @include flex;
    @include flexDirectionColumn;
}

@mixin horizontalLayout {
    @include flex;
    @include flexDirectionRow;
    width: 100%;
    height: 100%;
    min-width: 0;
}

.horizontalLayout {
    @include horizontalLayout;
}

@mixin verticalLayout {
    @include flex;
    @extend .flexDirectionColumn;
    width: 100%;
    height: 100%;
    min-width: 0;
}

.verticalLayout {
    @include verticalLayout;
}

@mixin colAuto {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
}

.colAuto {
    @include colAuto;
}

@mixin colAvailable {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1 1;
    -webkit-flex: 1 1;
    flex: 1 1;
    position: relative;
}

.colAvailable {
    @include colAvailable;
}

@mixin rowAuto {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
}

.rowAuto {
    @include rowAuto();
}

.rowAvailable {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1 1;
    -webkit-flex: 1 1;
    flex: 1 1;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

@mixin alignVerticalCenter {
    @include flex;
    align-content: center;
    align-items: center;
}


@mixin alignVCenter {
    @include flex;
    justify-content: center;
}

.alignVerticalCenter {
    @include alignVerticalCenter;
}

.alignVCenter {
    @include alignVCenter;
}

@mixin alignHorizontalCenter {
    margin: 0 auto;
}

.alignHorizontalCenter {
    @include alignHorizontalCenter;
}

@mixin alignFullCenter {
    display: flex; // make us of Flexbox
    align-items: center; // does vertically center the desired content
    justify-content: center; // horizontally centers single line items
}

.alignFullCenter {
    @include alignFullCenter;
}

.alignFullCenterAndCenterText {
    @include alignFullCenter;
    text-align: center;
}

.alignFlexHorizontalCenter {
    justify-content: center;
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}

@mixin wrapPanel {
    @include flex;
    flex-wrap: wrap;
}

.wrapPanel {
    @include wrapPanel;
}

.fullHeight {
    height: 100%;
}

.noScroll {
    overflow: hidden !important;
}

.fullWidth {
    width: 100%;
}

.extend{
    width: 100%;
    height: 100%;
}

.gridExtender{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;

    .singleCellExtend{
        grid-row: 1;
        grid-column: 1;
        width: 100%;
        height: 100%;
    }
}

.flexGhostElement {
    @include flex;
    flex: 1 1 250px;
    height: 0;
    opacity: 0;
    margin-right: 10px;
    background-color: red;
}

.scrollXVisible {
    overflow-x: visible !important;
}

.relativePos {
    position: relative;
}

.singleArea {
    padding: 5px 10px 0 10px;
}

.noPointerEvents{
    pointer-events: none;
}