input,
textarea {
    -webkit-user-select: auto;
    user-select: auto;
}

.inputLabel {
    @include secondaryText;
    text-align: left;
    margin: 0 0 8px 0;
}

.inputMessage {
    @include secondaryText;
    text-align: left;
    margin: 0 0 24px 0;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: none;

    .tooltipText {
        visibility: hidden;
        width: 240px;

        @include for-theme(light) {
            background-color: $light_tooltipColor;
        }

        @include for-theme(dark) {
            background-color: $dark_tooltipColor;
        }

        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 7px;
        margin-left: -120px;
        opacity: 0;
        transition: opacity 0.3s;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.2);

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;

            @include for-theme(light) {
                border-color: $light_tooltipColor transparent transparent transparent;
            }

            @include for-theme(dark) {
                border-color: $dark_tooltipColor transparent transparent transparent;
            }
        }

        span {
            @include primaryText;
        }
    }

    &:hover .tooltipText {
        visibility: visible;
        opacity: 1;
    }
}

input[type="text"] {
    padding: 8px 12px;
    height: $componentHeightDefault;
    margin: 0 0 8px 0;
    box-sizing: border-box;
    border-radius: $cornerRadiusDefault;
    outline: none;
    @include primaryText;
    transition: all 0.15s ease-out;


    @include for-theme(light) {
        border: 1px solid $light_inputBorderColor;
        background-color: $light_inputBackgroundColor;
    }

    @include for-theme(dark) {
        border: 1px solid $dark_inputBorderColor;
        background-color: $dark_inputBackgroundColor;
    }

    &:autofill,
    &:-webkit-autofill {
        @include for-theme(light) {
            color: $light_primaryColor;
            -webkit-text-fill-color: $light_primaryColor;
            border: 1px solid $light_inputBorderColor;
            background-color: $light_inputBackgroundColor;
        }

        @include for-theme(dark) {
            color: $dark_primaryColor;
            -webkit-text-fill-color: $dark_primaryColor;
            border: 1px solid $dark_inputBorderColor;
            background-color: $dark_inputBackgroundColor;
        }

        transition: background-color 5000s ease-in-out 0s;

        &:hover {
            @include for-theme(light) {
                border: 1px solid $light_inputBorderColorHover;
                background-color: $light_inputBackgroundColorHover;
            }

            @include for-theme(dark) {
                border: 1px solid $dark_inputBorderColorHover;
                background-color: $dark_inputBackgroundColorHover;
            }
        }

        &:focus {
            @include for-theme(light) {
                border: 1px solid $light_inputBorderColorFocus;
                background-color: $light_inputBackgroundColorFocus;
            }

            @include for-theme(dark) {
                border: 1px solid $dark_inputBorderColorFocus;
                background-color: $dark_inputBackgroundColorFocus;
            }
        }

    }


    &::placeholder {
        @include for-theme(light) {
            color: $light_inputPlaceholderColor;
        }

        @include for-theme(dark) {
            color: $dark_inputPlaceholderColor;
        }
    }


    &:hover {
        @include for-theme(light) {
            border: 1px solid $light_inputBorderColorHover;
            background-color: $light_inputBackgroundColorHover;
        }

        @include for-theme(dark) {
            border: 1px solid $dark_inputBorderColorHover;
            background-color: $dark_inputBackgroundColorHover;
        }
    }

    &:focus {
        @include for-theme(light) {
            border: 1px solid $light_inputBorderColorFocus;
            background-color: $light_inputBackgroundColorFocus;
        }

        @include for-theme(dark) {
            border: 1px solid $dark_inputBorderColorFocus;
            background-color: $dark_inputBackgroundColorFocus;
        }
    }

    &.large {
        height: $componentHeightLarge;
    }

    &.hasMessage {
        margin: 0;
    }
}