$accentColor: #901AED;
$accentColorHover: #ad51f8;
$accentColorActive: #8C2CD7;
$accentColorNegativeText: #ffffff;
$accentColorNegativeSecondaryText: #C9A0E9;



$light_backgroundColor: #fafafc;
$light_backgroundColor2: #ffffff;
$light_backgroundColor3: #f4f4f6;
$light_backgroundColor4: #f0f0f2;
$light_backgroundColor5: #e5e5e9;
$dark_backgroundColor: #19191C;
$dark_backgroundColor2: #242428;
$dark_backgroundColor3: #242428;
$dark_backgroundColor4: #f0f0f2;
$dark_backgroundColor5: #323236;



$light_primaryColor: #323232;
$light_secondaryColor: #868688;
$light_tertiaryColor: #a2a2a2;
$light_errorColor: #ff4356;
$light_successColor: #3f8f5f;
$dark_primaryColor: #ffffff;
$dark_secondaryColor: #848494;
$dark_tertiaryColor: #5B5B6B;
$dark_errorColor: #ff4356;
$dark_successColor: #57BA7F;


$light_bottomBarBackgroundColor: #F0F0F0;
$light_bottomBarBorderColor: #DDDDDD;
$dark_bottomBarBackgroundColor: #28282D;
$dark_bottomBarBorderColor: #4D4D4D;

$light_btnSecondaryBorderColor: #9797A9;
$light_btnSecondaryBackgroundHover: rgba($accentColor, 0.1);
$light_btnSecondaryBackgroundHover2: #797979;
$light_btnSecondaryBackgroundActive: rgba($accentColor, 0.2);
$dark_btnSecondaryBorderColor: #424242;
$dark_btnSecondaryBackgroundHover: rgba($accentColor, 0.1);
$dark_btnSecondaryBackgroundHover2: #797979;
$dark_btnSecondaryBackgroundActive: rgba($accentColor, 0.2);


$light_scrollThumbColor: #C9C9C9;
$light_scrollThumbColorHover: #B9b9b9;
$light_scrollThumbColorActive: #a9a9a9;
$dark_scrollThumbColor: #35353C;
$dark_scrollThumbColorHover: #474750;
$dark_scrollThumbColorActive: #323234;

$light_tooltipColor: #FFFFFF;
$dark_tooltipColor: #39393E;

$light_inputBackgroundColor: #FFFFFF;
$light_inputBackgroundColorHover: #FFFFFF;
$light_inputBackgroundColorFocus: #FFFFFF;
$light_inputBorderColor: #e0e0e0;
$light_inputBorderColorHover: #bbbbbb;
$light_inputBorderColorFocus: #808084;
$light_inputPlaceholderColor: #848494;
$dark_inputBackgroundColor: #2e2e31;
$dark_inputBackgroundColorHover: #35353a;
$dark_inputBackgroundColorFocus: #2e2e31;
$dark_inputBorderColor: transparent;
$dark_inputBorderColorHover: transparent;
$dark_inputBorderColorFocus: #74747a;
$dark_inputPlaceholderColor: #9595a0;

$light_componentSecondaryColor: #f0f0f2;
$light_componentSecondaryColorHover: #eaeaec;
$light_componentSecondaryColorActive: #DFDFDF;
$dark_componentSecondaryColor: $dark_backgroundColor2;
$dark_componentSecondaryColorHover: #35353C;
$dark_componentSecondaryColorActive: #2b2b2e;

$light_differenceColor: #FFD600;
$dark_differenceColor: #FFD600;
$light_differenceTextColor: #19191C;
$dark_differenceTextColor: #19191C;


//TODO Check if colors are still needed or need to be refactored
$accentComplementaryColor: #0C8CE9;
$dark_backgroundColor_500: #28282A;
$dark_backgroundColor_300: #37373A;
$light_backgroundColor_300: #DFDFDF;

$dark_logoAccentGradientStep1: #C183FF;
$dark_logoAccentGradientStep2: #E3D2FF;



$light_webPrimaryColor: #323232;
$light_webSecondaryColor: #868688;
$light_webTertiaryColor: #a2a2a2;
$dark_webPrimaryColor: #ffffff;
$dark_webSecondaryColor: #a9a9B4;
$dark_webTertiaryColor: #848494;