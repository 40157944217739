@import "./scss/_dimensions";
@import "./scss/_media";
@import "./scss/_colors";
@import "./scss/_typography";
@import "./scss/_scroll";
@import "./scss/_layout";
@import "./scss/_loaders";
@import "./scss/_buttons";
@import "./scss/_input";
@import "./scss/_lists";
@import "./scss/_tooltip";
@import "./scss/_pages";

// #region Font

@font-face {
  font-family: AvenirNextLTPro-Medium;
  src: url(./fonts/AvenirNextLTPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: AvenirNextLTPro-Bold;
  src: url(./fonts/AvenirNextLTPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: AvenirNextLTPro-Demi;
  src: url(./fonts/AvenirNextLTPro-Demi.ttf) format('truetype');
}

@font-face {
  font-family: Inter-Medium;
  src: url(./fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(./fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: Inter-Bold;
  src: url(./fonts/Inter-Bold.ttf) format('truetype');
}

// #endregion

// #region Html, Body

html {
  box-sizing: border-box;
  background: transparent;
  overflow: hidden;
  cursor: default;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;

  @include for-theme(light) {
    background-color: $light_backgroundColor;
    color: $light_primaryColor;
  }

  @include for-theme(dark) {
    background-color: $dark_backgroundColor;
    color: $dark_primaryColor;
  }
}

#root {
  width: 100%;
  height: 100%;
  @include flex;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.innerCanvas {
  @include for-theme(light) {
    background-color: $light_backgroundColor;
    color: $light_primaryColor;
  }

  @include for-theme(dark) {
    background-color: $dark_backgroundColor;
    color: $dark_primaryColor;
  }
}


*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  position: relative;

  /* Prevent the content from being selectionable */
  -webkit-user-select: none;
  user-select: none;
}

// #endregion

// #region Window, backgrounds, and panels

.dotted {
  background-size: 20px 20px;
  background-position: -19px -19px;


  @include for-theme(light) {
    background-color: $light_backgroundColor2;
    color: $light_primaryColor;
  }

  @include for-theme(dark) {
    background-color: $dark_backgroundColor;
    color: $dark_primaryColor;
  }

  @include for-theme(light) {
    background-image: radial-gradient(rgba($light_primaryColor,0.1) 1px, transparent 0);
  }

  @include for-theme(dark) {
    background-image: radial-gradient(rgba($dark_primaryColor,0.07) 1px, transparent 0);
  }
}

// #endregion



// #region Animations (fades)

@mixin animatedDiv {
  &.visible {
    transition: visibility 0s, opacity 0s;
    visibility: visible;
    opacity: 1;
  }

  &.invisible {
    transition: visibility 0s, opacity 0s;
    visibility: hidden;
    opacity: 0;
  }

  &.fadeOut {
    transition: opacity 0.5s linear, visibility 0s 0.5s !important;
    opacity: 0 !important;
    visibility: hidden !important;

    &.fast{
      transition: opacity 0.2s linear, visibility 0s 0.2s !important;
    }
  }

  &.fadeIn {
    transition: visibility 0s, opacity 0.5s linear !important;
    opacity: 1 !important;
    visibility: visible !important;

    &.fast{
      transition: visibility 0s, opacity 0.2s linear !important;
    }
  }
}

.animatedDiv {
  @include animatedDiv;
}

// #endregion

// #region Links(a)

a {
  color: inherit;
}

// #endregion