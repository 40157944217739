@import "./_typography";

.listItem {

    @include rowAuto;
    @include alignVerticalCenter;
    padding: 4px 8px;
    margin-bottom: 1px;
    margin-right: 4px;
    cursor: pointer;

    @include primaryText;
    background-color: transparent;
    transition: all 0.2s ease-out;

    &:hover {
        @include for-theme(light) {
            background-color: $light_componentSecondaryColorHover;
        }

        @include for-theme(dark) {
            background-color: $dark_componentSecondaryColorHover;
        }
    }

    &.selected {
        background-color: $accentColor;
        color: $accentColorNegativeText;

        .primaryText {
            color: $accentColorNegativeText;
        }

        .secondaryText {
            color: $accentColorNegativeSecondaryText;
        }
    }

    span {
        @include primaryText;
        width: 100%;
        margin: 4px 10px 4px 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }




}