$mobileBreak: 460px;
$smallScreenBreak: 860px;
$smallDesktopBreak: 1280px;
$largeDesktopBreak: 2400px;

@mixin for-theme($theme) {
  @if $theme ==light {
    @media (prefers-color-scheme: light) {
      @content;
    }
  }

  @else if $theme ==dark {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }
}

@mixin mobileScreen {
  @media (max-width: $mobileBreak) {
    @content;
  }
}

@mixin smallScreen {
  @media (max-width: $smallScreenBreak) {
    @content;
  }
}

@mixin smallDesktop {
  @media (max-width: $smallDesktopBreak) {
    @content;
  }
}

@mixin largeDesktop {
  @media (min-width: $largeDesktopBreak) {
    @content;
  }
}