@mixin primaryText {
    font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;

    @include for-theme(light) {
        color: $light_primaryColor;
    }

    @include for-theme(dark) {
        color: $dark_primaryColor;
    }
}

.primaryText {
    @include primaryText;
}

@mixin secondaryText {
    font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;

    &.spaced {
        line-height: 20px;
    }

    @include for-theme(light) {
        color: $light_secondaryColor;
    }

    @include for-theme(dark) {
        color: $dark_secondaryColor;
    }
}

.secondaryText {
    @include secondaryText;
}

@mixin errorText {
    font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;

    &.spaced {
        line-height: 20px;
    }

    @include for-theme(light) {
        color: $light_errorColor;
    }

    @include for-theme(dark) {
        color: $dark_errorColor;
    }
}

@mixin successText {
    font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;

    &.spaced {
        line-height: 20px;
    }

    @include for-theme(light) {
        color: $light_successColor;
    }

    @include for-theme(dark) {
        color: $dark_successColor;
    }
}

.errorText {
    @include errorText;
}

.successText {
    @include successText;
}

@mixin webPrimaryText {
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    line-height: 150%;

    @include for-theme(light) {
        color: $light_webPrimaryColor;
    }

    @include for-theme(dark) {
        color: $dark_webPrimaryColor;
    }
}

.webPrimaryText {
    @include webPrimaryText;
}

@mixin webSecondaryText {
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    line-height: 150%;

    @include for-theme(light) {
        color: $light_webSecondaryColor;
    }

    @include for-theme(dark) {
        color: $dark_webSecondaryColor;
    }
}

.webSecondaryText {
    @include webSecondaryText;
}

@mixin webTertiaryText {
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    line-height: 150%;

    @include for-theme(light) {
        color: $light_webTertiaryColor;
    }

    @include for-theme(dark) {
        color: $dark_webTertiaryColor;
    }
}

.webTertiaryText {
    @include webTertiaryText;
}



@mixin webAccentText {
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    line-height: 150%;

    @include for-theme(light) {
        color: #615AB8;
    }

    @include for-theme(dark) {
        color: #ABA4FF;
    }
}

.webAccentText {
    @include webTertiaryText;
}

h1 {
    font-family: Inter-Bold, sans-serif;
    font-size: 48px;

    @include smallScreen {
        line-height: 120%;
    }
}

h2 {
    font-family: Inter-Bold, sans-serif;
    font-size: 32px;

    @include smallScreen {
        line-height: 120%;
    }
}

h3 {
    font-family: Inter-SemiBold, sans-serif;
    font-size: 24px;

    @include smallScreen {
        line-height: 120%;
    }
}

h6 {
    font-family: Inter-Medium, sans-serif;
    font-size: 18px;
}