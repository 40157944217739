.app {
    .logo {

        width: 140px;

        svg {
            width: 100%;
            height: 100%;
        }

        img {
            width: 124px;
            height: 28px;
        }

        #part1,
        #bar {
            @include for-theme(light) {
                fill: $light_primaryColor;
            }

            @include for-theme(dark) {
                fill: $dark_primaryColor;
            }
        }

        #part2 {
            @include for-theme(light) {
                fill: $light_tertiaryColor;
            }

            @include for-theme(dark) {
                fill: $dark_tertiaryColor;
            }
        }

    }

    .userData {

        position: absolute;
        bottom: 16px;
        left: 24px;

        img {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            margin-right: 8px;
            background-color: rgba(255, 255, 255, 0.1);
            border: none;
        }
    }
}

.mainSite {

    .placeholderMessage{
        width:480px;
        text-align: center;
        line-height: 24px;
    }

    .alignVCenter {
        margin-left: 10%;
        margin-bottom: 90px;

        @include smallDesktop {
            margin-left: 128px;
        }

        @include largeDesktop {
            margin-left: 240px;
        }
    }

    .content {
        max-width: $smallDesktopBreak;
        @include alignHorizontalCenter;
    }

    .hero {
        padding: 80px 40px 0 40px;
        max-width: 860px;
        @include alignHorizontalCenter;
        margin-bottom: 64px;
    }

    .logoCircle {
        width: 96px;
        height: 96px;
        margin-bottom: 40px;
    }

    .productName {
        text-align: center;
        margin-bottom: 40px;

        .beta {
            height: 24px;
            padding: 4px 8px;
            margin-left: 8px;
            border-radius: 4px;
            font-family: Inter-Bold, sans-serif;
            font-size: 12px;
            @include alignFullCenter;
            text-transform: uppercase;
            background-color: $accentColor;
            color: $accentColorNegativeText;
        }
    }

    .title {
        text-align: center;
        margin-bottom: 32px;

        .decorated {
            @include for-theme(light) {
                border: 1px solid $light_differenceColor;
            }

            @include for-theme(dark) {
                border: 1px solid $dark_differenceColor;
            }

            &::after {
                content: "Changed";
                position: absolute;
                left: -1px;
                top: -20px;
                height: 20px;
                padding: 4px 8px;
                border-radius: 2px 2px 0 0;
                font-size: 10px;
                @include alignFullCenter;
                text-transform: uppercase;

                @include for-theme(light) {
                    background-color: $light_differenceColor;
                    color: $light_differenceTextColor;
                }

                @include for-theme(dark) {
                    background-color: $dark_differenceColor;
                    color: $dark_differenceTextColor;
                }
            }
        }
    }


    .subtitle {
        @include webSecondaryText;
        text-align: center;
        margin-bottom: 56px;
    }

    .linkUrlInput {
        margin-bottom: 8px;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 8px;

        @include mobileScreen {
            grid-template-columns: 1fr;
        }

        .inputField {
            grid-column: 1;
            height: $componentHeightExtraLarge;
            padding: 12px 16px;
            @include webPrimaryText;

            @include mobileScreen {
                grid-column: 1;
                order: 1;
            }
        }

        .btnPrimary {
            grid-column: 2;
            width: 160px;
            height: $componentHeightExtraLarge;
            font-size: 16px;
            font-family: Inter-SemiBold, sans-serif;

            @include mobileScreen {
                grid-column: 1;
                order: 2;
                width: 100%;
            }
        }


    }


    .startHere {
        width: 320px;
        margin-top: 8px;
        margin-left: 48px;

        @include mobileScreen {
            margin-left: 0;
            width: 280px;
        }

        img {
            width: 100%;
            ;
        }
    }

    .comparer {

        padding: 80px 40px 0 40px;
        @include alignHorizontalCenter;
        margin-bottom: 120px;


        @include smallScreen {
            padding: 48px 24px 0 24px;
        }

        .screen {
            border-radius: 36px;
            padding: 24px;
            margin-bottom: 8px;

            @include smallScreen {
                border-radius: 12px;
                padding: 8px;
            }

            @include for-theme(light) {
                background-color: #0f0f0f;
            }

            @include for-theme(dark) {
                background-color: #323234;
            }


            .content {
                width: 100%;
                height: 100%;
                border-radius: 16px;
            }
        }

        .imageNote {
            margin-left: 24px;
            @include webSecondaryText;
            font-size: 12px;

            @include smallScreen {
                margin-left: 4px;
            }
        }
    }

    .twoColumnsContentBlock {
        max-width: $smallDesktopBreak;
        @include alignHorizontalCenter;
        @include alignVerticalCenter;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 160px;
        padding-left: 40px;
        padding-right: 40px;

        @include smallScreen {
            grid-template-columns: 1fr;
            margin-bottom: 120px;
        }

        .btnSecondary {
            width: 120px;
        }

        &.leftContent {

            .left {
                grid-column: 1;

                @include smallScreen {
                    margin-bottom: 40px;
                    order: 1;
                }
            }

            .right {
                grid-column: 2;

                @include smallScreen {
                    grid-column: 1;
                    order: 2;
                }
            }
        }

        &.rightContent {

            .left {
                grid-column: 1;

                @include smallScreen {
                    order: 2;
                    @include alignHorizontalCenter;
                }
            }

            .right {
                grid-column: 2;

                @include smallScreen {
                    grid-column: 1;
                    order: 1;
                    margin-bottom: 40px;
                }
            }
        }

        .blockTitle {
            margin-bottom: 32px;

            @include smallScreen {
                width: 100%;
            }
        }

        .blockDescription {
            width: 480px;
            @include webSecondaryText;

            @include smallScreen {
                width: 100%;
            }

            p {
                margin-bottom: 16px;
            }

            .highlight {
                @include webPrimaryText;
                font-family: Inter-Bold, sans-serif;
            }

            .iconButton {
                margin-top: 16px;
                width: 36px;
                margin-right: 8px;

                path {
                    @include for-theme(light) {
                        fill: $light_primaryColor;
                    }

                    @include for-theme(dark) {
                        fill: $dark_primaryColor;
                    }
                }
            }
        }

        .blockComparer {
            width: 100%;
            max-width: 460px;
            margin-bottom: 24px;



            @include mobileScreen {
                max-width: 280px;
            }

            @include smallScreen {
                max-width: 360px;
            }
        }

        .mediumImage {
            max-width: 520px;
            @include alignHorizontalCenter;
        }

        .blockImage {
            width: 100%;
            max-width: 460px;
            @include flex;
            margin-bottom: 24px;
            position: relative;

            @include mobileScreen {
                max-width: 280px;
            }

            @include smallScreen {
                max-width: 360px;
            }

            img {
                @include alignHorizontalCenter;
                max-width: 100%;
                height: 100%;
            }

            &.typeOverlay {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .optionButton {
            margin-right: 8px;
        }

    }

    .fullWidthBlock {
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 160px;

        @include smallScreen {
            margin-bottom: 120px;
        }

        h1 {
            margin-bottom: 48px;
        }

        h3 {
            margin-bottom: 8px;

            @include smallScreen {
                margin-bottom: 24px;
            }
        }

        .subtitle {
            margin-bottom: 120px;
        }

        .pricing {
            max-width: 1000px;
            @include alignHorizontalCenter;
            margin-bottom: 80px;
        }

        .twoColumns {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @include smallScreen {
                grid-template-columns: 1fr;
                grid-gap: 32px;
            }
        }

        .startTrial {

            max-width: 800px;
            @include alignHorizontalCenter;

            .subtitle {
                margin-bottom: 48px;
            }
        }

        &.footer {

            max-width: 1000px;
            @include alignHorizontalCenter;

            h1 {
                margin-bottom: 16px;
            }

            .title {
                margin-bottom: 16px;
            }

            .subtitle {
                margin-bottom: 64px;
            }

            .startTrial {
                margin-bottom: 96px;
            }

            .webSecondaryText {
                font-size: 14px;
                text-align: center;
                @include alignHorizontalCenter;
                margin-bottom: 240px;
            }
        }

        .pricingBlock {
            padding: 64px 64px 48px 64px;

            @include smallScreen {
                padding: 32px 32px 32px 32px;
            }

            &.left {
                grid-column: 1;

                @include smallScreen {
                    max-height: none;
                    grid-column: 1;
                    order: 1;
                }
            }

            &.right {
                margin-left: -32px;
                margin-top: -32px;
                grid-column: 2;

                @include smallScreen {
                    grid-column: 1;
                    order: 2;
                    margin-left: 0;
                    margin-top: 0;
                }

                .price {
                    margin-bottom: 64px;

                    @include smallScreen {
                        margin-bottom: 24px;
                    }
                }

                @include smallScreen {
                    img {
                        margin-bottom: 48px;
                    }
                }
            }

            img {
                @include flex;
                @include alignHorizontalCenter;
                width: 100%;
                max-width: 280px;
                margin-bottom: 32px;
                text-align: center;
            }

            .title {
                @include webPrimaryText;
                text-align: left;
                margin-bottom: 16px;
            }

            .subtitle {
                @include webSecondaryText;
                text-align: left;
                margin-bottom: 16px;
            }

            .price {
                @include webAccentText;
                text-align: left;
                margin-bottom: 24px;
            }

            .btnPrimary,
            .btnSecondary {
                height: 56px;
                border-radius: 12px;
                font-size: 16px;
                width: 100%;
            }

            border-radius: 40px;

            @include for-theme(light) {
                background-color: #ffffff;
                box-shadow: 0px 12px 17px 1px rgba(0, 0, 0, 0.1);
            }

            @include for-theme(dark) {
                background-color: #26262a;
                box-shadow: 0px 12px 17px 1px rgba(0, 0, 0, 0.1);
            }

        }
    }

    .faqItems {


        .faqItem {
            padding: 48px 64px;
            max-width: 1000px;
            @include alignHorizontalCenter;

            @include smallScreen {
                padding: 32px 16px;
            }

            @include for-theme(light) {
                border-bottom: 1px solid transparent;
                border-image: linear-gradient(0.25turn, transparent, $light_backgroundColor5, $light_backgroundColor5, transparent);
                border-image-slice: 1;
            }

            @include for-theme(dark) {
                border-bottom: 1px solid transparent;
                border-image: linear-gradient(0.25turn, transparent, $dark_backgroundColor5, $dark_backgroundColor5, transparent);
                border-image-slice: 1;
            }

            &:last-of-type {
                border: none;
            }

            .faqTitle {
                text-align: center;
                @include webPrimaryText;
                font-family: Inter-SemiBold, sans-serif;
                font-size: 20px;
                margin-bottom: 24px;
            }

            .faqDescription {
                text-align: center;
                @include webSecondaryText;


                .highlight {
                    @include webPrimaryText;
                    text-decoration: underline;
                    font-family: Inter-Bold, sans-serif;
                }
            }

            p {
                @include alignHorizontalCenter;
                margin-bottom: 32px;
                max-width: 600px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}



.licenseOverlay {

    @include for-theme(light) {
        background-color: $light_backgroundColor;
        color: $light_primaryColor;
    }

    @include for-theme(dark) {
        background-color: $dark_backgroundColor;
        color: $dark_primaryColor;
    }

    @include for-theme(light) {
        background-image: url('./images/bgLight.png');
    }

    @include for-theme(dark) {
        background-image: url('./images/bgDark.png');
    }

    background-repeat: no-repeat;
    background-position: right bottom;

    .logoBig {
        width: 446px;
        height: 80px;
        margin-bottom: 32px;


        #figma {
            @include for-theme(light) {
                fill: $light_primaryColor;
            }

            @include for-theme(dark) {
                fill: $dark_primaryColor;
            }
        }

        #history {
            @include for-theme(light) {
                fill: url(#history_dark_gradient) #fff;
            }

            @include for-theme(dark) {
                fill: url(#history_dark_gradient) #fff;
            }
        }

    }

    .mainContent {
        margin-left: 10%;
        margin-bottom: 90px;
    }

    .alignVCenter {

        @include smallDesktop {
            margin-left: 128px;
        }

        @include largeDesktop {
            margin-left: 240px;
        }
    }

    .primaryText {
        font-size: 16px;
        margin-bottom: 12px;
    }

    .options {
        margin-top: 24px;
        margin-bottom: 40px;

        button {
            float: left;
            margin-right: 8px;
            width: 200px;
        }
    }

    .btnSecondary {
        min-width: 100px;
    }

    .label {
        width: 50%;
        text-align: left;
        margin-bottom: 8px;
    }

    .linkUrlInput {
        @include flex;
        height: $componentHeightLarge;
        margin-right: $horizontalSeparationDefault;
    }

    .inputForm {
        width: 500px;
        max-width: 1600px;
        margin-top: 32px;
        margin-bottom: 56px;
    }

    .btnActivate {
        width: 170px;

        #indeterminateLoader {
            margin-left: 8px;
        }

        &.success {
            @include for-theme(light) {
                background-color: $light_successColor;
            }

            @include for-theme(dark) {
                background-color: $dark_successColor;
            }
        }
    }

}

.loader {
    .alignFullCenter {
        bottom: 24px;
    }

    .dualRingLoader {
        margin-bottom: 16px;
    }
}

.comparer {

    .bigFilesDisclaimer{
        position: absolute;
        bottom:12px;
        left:50%;
        @include secondaryText;
        padding: 8px 12px;
        border-radius: 6px;
        transform: translate(-50%,0);
        text-align: center;
        backdrop-filter: blur(20px);

        @include for-theme(light) {
            background-color: rgba($light_backgroundColor2,0.85);
            border: 1px solid $light_backgroundColor5;
        }

        @include for-theme(dark) {
            background-color: rgba($dark_backgroundColor2,0.85);
            border: 1px solid $dark_backgroundColor5;
        }
    }

    .canvasVersionOverlay {
        @include flex;


        width: 300px;

        .select {
            width: 100%;
        }
    }


    .reactselect__control {
        border-radius: $cornerRadiusDefault;
        backdrop-filter: blur(10px);

        @include for-theme(light) {
            background-color: rgba($light_componentSecondaryColor, 0.8);
        }

        @include for-theme(dark) {
            background-color: rgba($dark_componentSecondaryColor, 0.8);
        }

        &:hover {
            @include for-theme(light) {
                background-color: rgba($light_componentSecondaryColorHover, 0.8);
            }

            @include for-theme(dark) {
                background-color: rgba($dark_componentSecondaryColorHover, 0.8);
            }
        }

        svg {
            path {
                @include for-theme(light) {
                    fill: $light_secondaryColor;
                }

                @include for-theme(dark) {
                    fill: $dark_secondaryColor;
                }
            }
        }
    }


    .reactselect__menu {
        border-radius: $cornerRadiusDefault;
        overflow: hidden;
        margin-top: 2px;
        padding: 4px;
        backdrop-filter: blur(20px);

        @include for-theme(light) {
            background-color: rgba($light_backgroundColor, 0.9);
            border: 1px solid $light_componentSecondaryColor;
        }

        @include for-theme(dark) {
            background-color: rgba($dark_backgroundColor, 0.9);
            border: 1px solid $dark_componentSecondaryColor;
        }

    }

    .reactselect__menu-list {
        padding: 0 4px 0 0;
    }

    .reactselect__indicators {
        padding: 0 24px 0 0;

        .reactselect__indicator-separator {
            margin-right: 8px;
        }
    }

    .compareHandle {
        .__rcs-handle-line {
            @include for-theme(light) {
                background-color: $light_backgroundColor4 !important;
            }

            @include for-theme(dark) {
                background-color: $dark_backgroundColor4 !important;
            }
        }
    }

    .sidebysideHandle {
        .__rcs-handle-line {
            @include for-theme(light) {
                background-color: $light_backgroundColor !important;
            }

            @include for-theme(dark) {
                background-color: $dark_backgroundColor2 !important;
            }
        }
    }

    .versionContainer {
        padding: 12px 16px;
        transition: all 0.1s ease-out;

        img {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            margin-right: 8px;
            background-color: rgba(255, 255, 255, 0.1);
            border: none;
        }

        .small {
            font-size: 13px;
        }

        .dummy-input-wrapper {
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
        }


    }

    .versionOption {

        padding: 12px 16px;
        transition: all 0.1s ease-out;
        border-radius: 2px;



        &:hover,
        &:focus {
            @include for-theme(light) {
                background-color: $light_componentSecondaryColorHover;
            }

            @include for-theme(dark) {
                background-color: $dark_componentSecondaryColorHover;
            }
        }

        img {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            margin-right: 8px;
            background-color: rgba(255, 255, 255, 0.1);
            border: none;
        }

        .small {
            font-size: 13px;
        }
    }

    .comparePadded {
        padding-left: 50%;
    }

    .differenceHeader {
        @include primaryText;
        @include alignFullCenter;
        padding: 8px;
        border-radius: 4px;

        @include for-theme(light) {
            background-color: $light_differenceColor;
            color: $light_differenceTextColor;
        }

        @include for-theme(dark) {
            background-color: $dark_differenceColor;
            color: $dark_differenceTextColor;
        }
    }

    .differenceOutline {

        @include for-theme(light) {
            border: 2px solid $light_differenceColor;
        }

        @include for-theme(dark) {
            border: 2px solid $dark_differenceColor;
        }
    }

    .differenceArtboard {
        @include for-theme(light) {
            backgroundColor: rgba(0, 0, 0, 0.05);
        }

        @include for-theme(dark) {
            backgroundColor: rgba(255, 255, 255, 0.05);
        }
    }

    // #region Bottom Bar

    .sidePanel {
        width: 240px;
        transition: all 0.3s ease-out;

        @include for-theme(light) {
            background-color: $light_backgroundColor;
        }

        @include for-theme(dark) {
            background-color: $dark_backgroundColor2;
        }

        padding-top:24px;
        padding-bottom:64px;


        &.collapsed {
            width: 0;
            opacity: 0;
        }

        .logo {
            padding-left: 24px;
            width: 160px;
            margin-bottom: 32px;
        }

        .title {
            @include primaryText;
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 32px;
            font-size: 14px;

            &.hasSubtitle {
                margin-bottom: 4px;
            }
        }

        .subtitle {
            @include secondaryText;
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 32px;
        }

        .header {
            @include secondaryText;
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 16px;
        }

        .listItem {
            padding: 8px 24px 10px 24px;
        }

        .trialMessage {
            padding: 24px;

            .secondaryText {
                margin-bottom: 16px;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .btnPrimary {
                width: 100%;
                margin-bottom: 16px;
            }

            .btnSecondary {
                width: 100%;
            }
        }
    }

    // #endregion

    // #region Bottom Bar

    .bottomBar {

        // height:60px;
        padding: 12px 12px 12px 12px;

        @include for-theme(light) {
            background-color: $light_backgroundColor2;
            border-top: 1px solid $light_backgroundColor3;
        }

        @include for-theme(dark) {
            background-color: $dark_backgroundColor;
            border-top: 1px solid $dark_backgroundColor3;
        }

        display: grid;
        grid-template-columns: 2fr 1fr 2fr;

        .btnSecondary {
            width: 100px;
            height: 36px;

            &.viewOption {
                margin-right: 8px;
            }

            &.iconButton {
                width: 36px;
                margin-right: 8px;

                path {
                    @include for-theme(light) {
                        fill: $light_primaryColor;
                    }

                    @include for-theme(dark) {
                        fill: $dark_primaryColor;
                    }
                }

                &:hover {
                    @include for-theme(light) {
                        border-color: $light_btnSecondaryBackgroundHover2;
                    }

                    @include for-theme(dark) {
                        border-color: $dark_btnSecondaryBackgroundHover2;
                    }
                }

                &.checked {
                    @include for-theme(light) {
                        border-color: $accentColor;
                    }

                    @include for-theme(dark) {
                        border-color: $accentColor;
                    }

                    &:hover {
                        @include for-theme(light) {
                            border-color: $accentColorHover;
                        }

                        @include for-theme(dark) {
                            border-color: $accentColorHover;
                        }
                    }

                    path {
                        @include for-theme(light) {
                            fill: $accentColor;
                        }

                        @include for-theme(dark) {
                            fill: $dark_primaryColor;
                        }
                    }
                }
            }


        }

        span {
            margin-right: 8px;
        }

        .leftElements {
            grid-column: 1;

            @include smallDesktop {
                display: none;
            }
        }

        .centerElements {
            grid-column: 2;
        }

        .rightElements {
            grid-column: 3;
            justify-content: flex-end;

            @include smallDesktop {
                display: none;
            }
        }
    }

    // #endregion
}

.productHuntBadge {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 250px;
    height: 54px;

    @include smallScreen {
        display: none;
    }
}