.indeterminateLoader {
    min-height: 100px;
    width: 100%;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .message{
        margin-bottom: 4px;
    }

    .loaderMessage {
        @include secondaryText;
        margin-top: 16px;
    }

    .indeterminateLoaderRing {
        content: "";
        display: inline-block;
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        margin-top: -25px;
        left: 50%;
        margin-left: -25px;
        z-index: 1;
        border-radius: 50%;
        border: 3px solid $accentColor;
        border-left-color: transparent;
        background: transparent;
        animation: spinnerAnimation 0.7s infinite linear;
    }


    .dualRingLoader {
        display: inline-block;
        width: 42px;
        height: 42px;

        &:after {
            content: " ";
            display: block;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 2px solid rgb(105, 105, 105);
            border-color: rgb(105, 105, 105) transparent rgb(105, 105, 105) transparent;
            animation: dualRingLoader 1.2s linear infinite;
        }
    }

    &.small {
        min-height: 0;
        width: 20px;
        height: 20px;

        .dualRingLoader {
            width: 20px;
            height: 20px;

            &:after {
                width: 20px;
                height: 20px;
            }
        }
    }

    &.negative {
        align-self: center;
        .dualRingLoader {
            &:after {
                border: 2px solid $accentColorNegativeText;
                border-color: $accentColorNegativeText transparent $accentColorNegativeText transparent;
            }
        }
    }


    &.show {
        opacity: 1;
    }
}

@keyframes spinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}



@keyframes dualRingLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// #region CircularProgressbar 

.progressIndicatorContainer {
    width: 96px;
    height: 96px;
    margin-bottom: 16px;
}

.CircularProgressbar {
    .CircularProgressbar-trail {
        stroke-width: 4px;
        stroke: rgba(255, 255, 255, 0.05);
    }

    .CircularProgressbar-path {
        stroke-width: 4px;
        stroke: $accentColor;
    }
}


// #endregion

.pageLoader {
    position: absolute;
    top: 300px;
    left: 300px;
}