.primaryText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
}
@media (prefers-color-scheme: light) {
  .primaryText {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .primaryText {
    color: #ffffff;
  }
}

.secondaryText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
}
.secondaryText.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .secondaryText {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .secondaryText {
    color: #848494;
  }
}

.errorText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
}
.errorText.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .errorText {
    color: #ff4356;
  }
}
@media (prefers-color-scheme: dark) {
  .errorText {
    color: #ff4356;
  }
}

.successText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
}
.successText.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .successText {
    color: #3f8f5f;
  }
}
@media (prefers-color-scheme: dark) {
  .successText {
    color: #57BA7F;
  }
}

.webPrimaryText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webPrimaryText {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .webPrimaryText {
    color: #ffffff;
  }
}

.webSecondaryText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webSecondaryText {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .webSecondaryText {
    color: #a9a9B4;
  }
}

.webTertiaryText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webTertiaryText {
    color: #a2a2a2;
  }
}
@media (prefers-color-scheme: dark) {
  .webTertiaryText {
    color: #848494;
  }
}

.webAccentText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webAccentText {
    color: #a2a2a2;
  }
}
@media (prefers-color-scheme: dark) {
  .webAccentText {
    color: #848494;
  }
}

h1 {
  font-family: Inter-Bold, sans-serif;
  font-size: 48px;
}
@media (max-width: 860px) {
  h1 {
    line-height: 120%;
  }
}

h2 {
  font-family: Inter-Bold, sans-serif;
  font-size: 32px;
}
@media (max-width: 860px) {
  h2 {
    line-height: 120%;
  }
}

h3 {
  font-family: Inter-SemiBold, sans-serif;
  font-size: 24px;
}
@media (max-width: 860px) {
  h3 {
    line-height: 120%;
  }
}

h6 {
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
}

div::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}

div::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
@media (prefers-color-scheme: light) {
  div::-webkit-scrollbar-thumb {
    background-color: #C9C9C9;
  }
}
@media (prefers-color-scheme: dark) {
  div::-webkit-scrollbar-thumb {
    background-color: #35353C;
  }
}
@media (prefers-color-scheme: light) {
  div::-webkit-scrollbar-thumb:hover {
    background-color: #B9b9b9;
  }
}
@media (prefers-color-scheme: dark) {
  div::-webkit-scrollbar-thumb:hover {
    background-color: #474750;
  }
}
@media (prefers-color-scheme: light) {
  div::-webkit-scrollbar-thumb:active {
    background-color: #a9a9a9;
  }
}
@media (prefers-color-scheme: dark) {
  div::-webkit-scrollbar-thumb:active {
    background-color: #323234;
  }
}

.notDisplayed {
  display: none !important;
}

.notVisible {
  visibility: hidden !important;
}

.displayFlex {
  display: flex;
}

.flexDirectionRow {
  flex-direction: row;
}

.flexDirectionColumn, .verticalLayout {
  display: flex;
  flex-direction: column;
}

.horizontalLayout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-width: 0;
}

.verticalLayout {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 0;
}

.colAuto {
  flex: 0 0 auto;
  position: relative;
}

.colAvailable {
  flex: 1 1;
  position: relative;
}

.rowAuto {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.rowAvailable {
  flex: 1 1;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.alignVerticalCenter {
  display: flex;
  align-content: center;
  align-items: center;
}

.alignVCenter {
  display: flex;
  justify-content: center;
}

.alignHorizontalCenter {
  margin: 0 auto;
}

.alignFullCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignFullCenterAndCenterText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.alignFlexHorizontalCenter {
  justify-content: center;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.wrapPanel {
  display: flex;
  flex-wrap: wrap;
}

.fullHeight {
  height: 100%;
}

.noScroll {
  overflow: hidden !important;
}

.fullWidth {
  width: 100%;
}

.extend {
  width: 100%;
  height: 100%;
}

.gridExtender {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
}
.gridExtender .singleCellExtend {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
}

.flexGhostElement {
  display: flex;
  flex: 1 1 250px;
  height: 0;
  opacity: 0;
  margin-right: 10px;
  background-color: red;
}

.scrollXVisible {
  overflow-x: visible !important;
}

.relativePos {
  position: relative;
}

.singleArea {
  padding: 5px 10px 0 10px;
}

.noPointerEvents {
  pointer-events: none;
}

.indeterminateLoader {
  min-height: 100px;
  width: 100%;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.indeterminateLoader .message {
  margin-bottom: 4px;
}
.indeterminateLoader .loaderMessage {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  margin-top: 16px;
}
.indeterminateLoader .loaderMessage.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .indeterminateLoader .loaderMessage {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .indeterminateLoader .loaderMessage {
    color: #848494;
  }
}
.indeterminateLoader .indeterminateLoaderRing {
  content: "";
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -25px;
  z-index: 1;
  border-radius: 50%;
  border: 3px solid #901AED;
  border-left-color: transparent;
  background: transparent;
  animation: spinnerAnimation 0.7s infinite linear;
}
.indeterminateLoader .dualRingLoader {
  display: inline-block;
  width: 42px;
  height: 42px;
}
.indeterminateLoader .dualRingLoader:after {
  content: " ";
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid rgb(105, 105, 105);
  border-color: rgb(105, 105, 105) transparent rgb(105, 105, 105) transparent;
  animation: dualRingLoader 1.2s linear infinite;
}
.indeterminateLoader.small {
  min-height: 0;
  width: 20px;
  height: 20px;
}
.indeterminateLoader.small .dualRingLoader {
  width: 20px;
  height: 20px;
}
.indeterminateLoader.small .dualRingLoader:after {
  width: 20px;
  height: 20px;
}
.indeterminateLoader.negative {
  align-self: center;
}
.indeterminateLoader.negative .dualRingLoader:after {
  border: 2px solid #ffffff;
  border-color: #ffffff transparent #ffffff transparent;
}
.indeterminateLoader.show {
  opacity: 1;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes dualRingLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.progressIndicatorContainer {
  width: 96px;
  height: 96px;
  margin-bottom: 16px;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 4px;
  stroke: rgba(255, 255, 255, 0.05);
}
.CircularProgressbar .CircularProgressbar-path {
  stroke-width: 4px;
  stroke: #901AED;
}

.pageLoader {
  position: absolute;
  top: 300px;
  left: 300px;
}

.btnPrimary {
  border: none;
  border-radius: 6px;
  outline: none;
  font-family: AvenirNextLTPro-Demi, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  background-color: #901AED;
  transition: all 0.15s ease-out;
  color: #ffffff;
  padding: 6px 24px;
  height: 32px;
}
.btnPrimary:hover, .btnPrimary:focus {
  background-color: #ad51f8;
  outline: none;
}
.btnPrimary:active {
  background-color: #8C2CD7;
  border-color: #8C2CD7;
  outline: none;
}
.btnPrimary:disabled {
  background-color: #901AED;
  border-color: #901AED;
  outline: none;
  opacity: 0.3;
}
.btnPrimary.large {
  height: 40px;
}

.btnSecondary {
  background-color: transparent;
  height: 34px;
  border-radius: 6px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  outline: none;
  font-family: AvenirNextLTPro-Demi, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  transition: all 0.1s ease-out;
}
@media (prefers-color-scheme: light) {
  .btnSecondary {
    border: 1px solid #9797A9;
  }
}
@media (prefers-color-scheme: dark) {
  .btnSecondary {
    border: 1px solid #424242;
  }
}
@media (prefers-color-scheme: light) {
  .btnSecondary {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .btnSecondary {
    color: #ffffff;
  }
}
.btnSecondary:hover {
  outline: none;
}
@media (prefers-color-scheme: light) {
  .btnSecondary:hover {
    background-color: rgba(144, 26, 237, 0.1);
    border-color: #ad51f8;
  }
}
@media (prefers-color-scheme: dark) {
  .btnSecondary:hover {
    background-color: rgba(144, 26, 237, 0.1);
    border-color: #ad51f8;
  }
}
.btnSecondary:active {
  outline: none;
}
@media (prefers-color-scheme: light) {
  .btnSecondary:active {
    background-color: rgba(144, 26, 237, 0.2);
    border-color: #8C2CD7;
  }
}
@media (prefers-color-scheme: dark) {
  .btnSecondary:active {
    background-color: rgba(144, 26, 237, 0.2);
    border-color: #8C2CD7;
  }
}
@media (prefers-color-scheme: light) {
  .btnSecondary.checked {
    border-color: #901AED;
    color: #901AED;
  }
}
@media (prefers-color-scheme: dark) {
  .btnSecondary.checked {
    border-color: #901AED;
  }
}
.btnSecondary.large {
  height: 40px;
}

.btnTertiary {
  border: 1px solid transparent;
  background-color: transparent;
  width: 100px;
  height: 34px;
  border-radius: 6px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 0 auto;
  outline: none;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  transition: all 0.1s ease-out;
}
@media (prefers-color-scheme: light) {
  .btnTertiary {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .btnTertiary {
    color: #848494;
  }
}
.btnTertiary:hover {
  outline: none;
}
@media (prefers-color-scheme: light) {
  .btnTertiary:hover {
    border-color: #ad51f8;
  }
}
@media (prefers-color-scheme: dark) {
  .btnTertiary:hover {
    border-color: #ad51f8;
    color: #ffffff;
  }
}
.btnTertiary:active {
  outline: none;
}
@media (prefers-color-scheme: light) {
  .btnTertiary:active {
    border-color: #8C2CD7;
  }
}
@media (prefers-color-scheme: dark) {
  .btnTertiary:active {
    border-color: #8C2CD7;
  }
}
.btnTertiary.large {
  height: 40px;
}

input,
textarea {
  -webkit-user-select: auto;
  -moz-user-select: auto;
       user-select: auto;
}

.inputLabel {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  text-align: left;
  margin: 0 0 8px 0;
}
.inputLabel.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .inputLabel {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .inputLabel {
    color: #848494;
  }
}

.inputMessage {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  text-align: left;
  margin: 0 0 24px 0;
}
.inputMessage.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .inputMessage {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .inputMessage {
    color: #848494;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: none;
}
.tooltip .tooltipText {
  visibility: hidden;
  width: 240px;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 7px;
  margin-left: -120px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.2);
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText {
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText {
    background-color: #39393E;
  }
}
.tooltip .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText::after {
    border-color: #FFFFFF transparent transparent transparent;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText::after {
    border-color: #39393E transparent transparent transparent;
  }
}
.tooltip .tooltipText span {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText span {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText span {
    color: #ffffff;
  }
}
.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

input[type=text] {
  padding: 8px 12px;
  height: 32px;
  margin: 0 0 8px 0;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  transition: all 0.15s ease-out;
}
@media (prefers-color-scheme: light) {
  input[type=text] {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text] {
    color: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  input[type=text] {
    border: 1px solid #e0e0e0;
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text] {
    border: 1px solid transparent;
    background-color: #2e2e31;
  }
}
input[type=text]:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
input[type=text]:autofill, input[type=text]:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
@media (prefers-color-scheme: light) {
  input[type=text]:-webkit-autofill {
    color: #323232;
    -webkit-text-fill-color: #323232;
    border: 1px solid #e0e0e0;
    background-color: #FFFFFF;
  }
  input[type=text]:autofill, input[type=text]:-webkit-autofill {
    color: #323232;
    -webkit-text-fill-color: #323232;
    border: 1px solid #e0e0e0;
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text]:-webkit-autofill {
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    border: 1px solid transparent;
    background-color: #2e2e31;
  }
  input[type=text]:autofill, input[type=text]:-webkit-autofill {
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    border: 1px solid transparent;
    background-color: #2e2e31;
  }
}
@media (prefers-color-scheme: light) {
  input[type=text]:-webkit-autofill:hover {
    border: 1px solid #bbbbbb;
    background-color: #FFFFFF;
  }
  input[type=text]:autofill:hover, input[type=text]:-webkit-autofill:hover {
    border: 1px solid #bbbbbb;
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text]:-webkit-autofill:hover {
    border: 1px solid transparent;
    background-color: #35353a;
  }
  input[type=text]:autofill:hover, input[type=text]:-webkit-autofill:hover {
    border: 1px solid transparent;
    background-color: #35353a;
  }
}
@media (prefers-color-scheme: light) {
  input[type=text]:-webkit-autofill:focus {
    border: 1px solid #808084;
    background-color: #FFFFFF;
  }
  input[type=text]:autofill:focus, input[type=text]:-webkit-autofill:focus {
    border: 1px solid #808084;
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text]:-webkit-autofill:focus {
    border: 1px solid #74747a;
    background-color: #2e2e31;
  }
  input[type=text]:autofill:focus, input[type=text]:-webkit-autofill:focus {
    border: 1px solid #74747a;
    background-color: #2e2e31;
  }
}
@media (prefers-color-scheme: light) {
  input[type=text]::-moz-placeholder {
    color: #848494;
  }
  input[type=text]::placeholder {
    color: #848494;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text]::-moz-placeholder {
    color: #9595a0;
  }
  input[type=text]::placeholder {
    color: #9595a0;
  }
}
@media (prefers-color-scheme: light) {
  input[type=text]:hover {
    border: 1px solid #bbbbbb;
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text]:hover {
    border: 1px solid transparent;
    background-color: #35353a;
  }
}
@media (prefers-color-scheme: light) {
  input[type=text]:focus {
    border: 1px solid #808084;
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  input[type=text]:focus {
    border: 1px solid #74747a;
    background-color: #2e2e31;
  }
}
input[type=text].large {
  height: 40px;
}
input[type=text].hasMessage {
  margin: 0;
}

.primaryText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
}
@media (prefers-color-scheme: light) {
  .primaryText {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .primaryText {
    color: #ffffff;
  }
}

.secondaryText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
}
.secondaryText.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .secondaryText {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .secondaryText {
    color: #848494;
  }
}

.errorText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
}
.errorText.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .errorText {
    color: #ff4356;
  }
}
@media (prefers-color-scheme: dark) {
  .errorText {
    color: #ff4356;
  }
}

.successText {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
}
.successText.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .successText {
    color: #3f8f5f;
  }
}
@media (prefers-color-scheme: dark) {
  .successText {
    color: #57BA7F;
  }
}

.webPrimaryText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webPrimaryText {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .webPrimaryText {
    color: #ffffff;
  }
}

.webSecondaryText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webSecondaryText {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .webSecondaryText {
    color: #a9a9B4;
  }
}

.webTertiaryText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webTertiaryText {
    color: #a2a2a2;
  }
}
@media (prefers-color-scheme: dark) {
  .webTertiaryText {
    color: #848494;
  }
}

.webAccentText {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .webAccentText {
    color: #a2a2a2;
  }
}
@media (prefers-color-scheme: dark) {
  .webAccentText {
    color: #848494;
  }
}

h1 {
  font-family: Inter-Bold, sans-serif;
  font-size: 48px;
}
@media (max-width: 860px) {
  h1 {
    line-height: 120%;
  }
}

h2 {
  font-family: Inter-Bold, sans-serif;
  font-size: 32px;
}
@media (max-width: 860px) {
  h2 {
    line-height: 120%;
  }
}

h3 {
  font-family: Inter-SemiBold, sans-serif;
  font-size: 24px;
}
@media (max-width: 860px) {
  h3 {
    line-height: 120%;
  }
}

h6 {
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
}

.listItem {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 4px 8px;
  margin-bottom: 1px;
  margin-right: 4px;
  cursor: pointer;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  background-color: transparent;
  transition: all 0.2s ease-out;
}
@media (prefers-color-scheme: light) {
  .listItem {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .listItem {
    color: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .listItem:hover {
    background-color: #eaeaec;
  }
}
@media (prefers-color-scheme: dark) {
  .listItem:hover {
    background-color: #35353C;
  }
}
.listItem.selected {
  background-color: #901AED;
  color: #ffffff;
}
.listItem.selected .primaryText {
  color: #ffffff;
}
.listItem.selected .secondaryText {
  color: #C9A0E9;
}
.listItem span {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  width: 100%;
  margin: 4px 10px 4px 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}
@media (prefers-color-scheme: light) {
  .listItem span {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .listItem span {
    color: #ffffff;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: none;
}
.tooltip .tooltipText {
  visibility: hidden;
  width: 460px;
  visibility: hidden;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  padding: 10px 16px 14px 16px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  border-radius: 6px;
  z-index: 1;
  bottom: 150%;
  margin-left: -230px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.2);
  text-align: left;
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText {
    background-color: #FFFFFF;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText {
    background-color: #39393E;
  }
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText {
    color: #ffffff;
  }
}
.tooltip .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText::after {
    border-color: #FFFFFF transparent transparent transparent;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText::after {
    border-color: #39393E transparent transparent transparent;
  }
}
.tooltip .tooltipText span {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText span {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltipText span {
    color: #ffffff;
  }
}
.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.app .logo {
  width: 140px;
}
.app .logo svg {
  width: 100%;
  height: 100%;
}
.app .logo img {
  width: 124px;
  height: 28px;
}
@media (prefers-color-scheme: light) {
  .app .logo #part1,
  .app .logo #bar {
    fill: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .app .logo #part1,
  .app .logo #bar {
    fill: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .app .logo #part2 {
    fill: #a2a2a2;
  }
}
@media (prefers-color-scheme: dark) {
  .app .logo #part2 {
    fill: #5B5B6B;
  }
}
.app .userData {
  position: absolute;
  bottom: 16px;
  left: 24px;
}
.app .userData img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}

.mainSite .placeholderMessage {
  width: 480px;
  text-align: center;
  line-height: 24px;
}
.mainSite .alignVCenter {
  margin-left: 10%;
  margin-bottom: 90px;
}
@media (max-width: 1280px) {
  .mainSite .alignVCenter {
    margin-left: 128px;
  }
}
@media (min-width: 2400px) {
  .mainSite .alignVCenter {
    margin-left: 240px;
  }
}
.mainSite .content {
  max-width: 1280px;
  margin: 0 auto;
}
.mainSite .hero {
  padding: 80px 40px 0 40px;
  max-width: 860px;
  margin: 0 auto;
  margin-bottom: 64px;
}
.mainSite .logoCircle {
  width: 96px;
  height: 96px;
  margin-bottom: 40px;
}
.mainSite .productName {
  text-align: center;
  margin-bottom: 40px;
}
.mainSite .productName .beta {
  height: 24px;
  padding: 4px 8px;
  margin-left: 8px;
  border-radius: 4px;
  font-family: Inter-Bold, sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #901AED;
  color: #ffffff;
}
.mainSite .title {
  text-align: center;
  margin-bottom: 32px;
}
@media (prefers-color-scheme: light) {
  .mainSite .title .decorated {
    border: 1px solid #FFD600;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .title .decorated {
    border: 1px solid #FFD600;
  }
}
.mainSite .title .decorated::after {
  content: "Changed";
  position: absolute;
  left: -1px;
  top: -20px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 2px 2px 0 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
@media (prefers-color-scheme: light) {
  .mainSite .title .decorated::after {
    background-color: #FFD600;
    color: #19191C;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .title .decorated::after {
    background-color: #FFD600;
    color: #19191C;
  }
}
.mainSite .subtitle {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 56px;
}
@media (prefers-color-scheme: light) {
  .mainSite .subtitle {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .subtitle {
    color: #a9a9B4;
  }
}
.mainSite .linkUrlInput {
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
}
@media (max-width: 460px) {
  .mainSite .linkUrlInput {
    grid-template-columns: 1fr;
  }
}
.mainSite .linkUrlInput .inputField {
  grid-column: 1;
  height: 56px;
  padding: 12px 16px;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .mainSite .linkUrlInput .inputField {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .linkUrlInput .inputField {
    color: #ffffff;
  }
}
@media (max-width: 460px) {
  .mainSite .linkUrlInput .inputField {
    grid-column: 1;
    order: 1;
  }
}
.mainSite .linkUrlInput .btnPrimary {
  grid-column: 2;
  width: 160px;
  height: 56px;
  font-size: 16px;
  font-family: Inter-SemiBold, sans-serif;
}
@media (max-width: 460px) {
  .mainSite .linkUrlInput .btnPrimary {
    grid-column: 1;
    order: 2;
    width: 100%;
  }
}
.mainSite .startHere {
  width: 320px;
  margin-top: 8px;
  margin-left: 48px;
}
@media (max-width: 460px) {
  .mainSite .startHere {
    margin-left: 0;
    width: 280px;
  }
}
.mainSite .startHere img {
  width: 100%;
}
.mainSite .comparer {
  padding: 80px 40px 0 40px;
  margin: 0 auto;
  margin-bottom: 120px;
}
@media (max-width: 860px) {
  .mainSite .comparer {
    padding: 48px 24px 0 24px;
  }
}
.mainSite .comparer .screen {
  border-radius: 36px;
  padding: 24px;
  margin-bottom: 8px;
}
@media (max-width: 860px) {
  .mainSite .comparer .screen {
    border-radius: 12px;
    padding: 8px;
  }
}
@media (prefers-color-scheme: light) {
  .mainSite .comparer .screen {
    background-color: #0f0f0f;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .comparer .screen {
    background-color: #323234;
  }
}
.mainSite .comparer .screen .content {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.mainSite .comparer .imageNote {
  margin-left: 24px;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-size: 12px;
}
@media (prefers-color-scheme: light) {
  .mainSite .comparer .imageNote {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .comparer .imageNote {
    color: #a9a9B4;
  }
}
@media (max-width: 860px) {
  .mainSite .comparer .imageNote {
    margin-left: 4px;
  }
}
.mainSite .twoColumnsContentBlock {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 160px;
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
  }
}
.mainSite .twoColumnsContentBlock .btnSecondary {
  width: 120px;
}
.mainSite .twoColumnsContentBlock.leftContent .left {
  grid-column: 1;
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock.leftContent .left {
    margin-bottom: 40px;
    order: 1;
  }
}
.mainSite .twoColumnsContentBlock.leftContent .right {
  grid-column: 2;
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock.leftContent .right {
    grid-column: 1;
    order: 2;
  }
}
.mainSite .twoColumnsContentBlock.rightContent .left {
  grid-column: 1;
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock.rightContent .left {
    order: 2;
    margin: 0 auto;
  }
}
.mainSite .twoColumnsContentBlock.rightContent .right {
  grid-column: 2;
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock.rightContent .right {
    grid-column: 1;
    order: 1;
    margin-bottom: 40px;
  }
}
.mainSite .twoColumnsContentBlock .blockTitle {
  margin-bottom: 32px;
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock .blockTitle {
    width: 100%;
  }
}
.mainSite .twoColumnsContentBlock .blockDescription {
  width: 480px;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .mainSite .twoColumnsContentBlock .blockDescription {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .twoColumnsContentBlock .blockDescription {
    color: #a9a9B4;
  }
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock .blockDescription {
    width: 100%;
  }
}
.mainSite .twoColumnsContentBlock .blockDescription p {
  margin-bottom: 16px;
}
.mainSite .twoColumnsContentBlock .blockDescription .highlight {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-family: Inter-Bold, sans-serif;
}
@media (prefers-color-scheme: light) {
  .mainSite .twoColumnsContentBlock .blockDescription .highlight {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .twoColumnsContentBlock .blockDescription .highlight {
    color: #ffffff;
  }
}
.mainSite .twoColumnsContentBlock .blockDescription .iconButton {
  margin-top: 16px;
  width: 36px;
  margin-right: 8px;
}
@media (prefers-color-scheme: light) {
  .mainSite .twoColumnsContentBlock .blockDescription .iconButton path {
    fill: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .twoColumnsContentBlock .blockDescription .iconButton path {
    fill: #ffffff;
  }
}
.mainSite .twoColumnsContentBlock .blockComparer {
  width: 100%;
  max-width: 460px;
  margin-bottom: 24px;
}
@media (max-width: 460px) {
  .mainSite .twoColumnsContentBlock .blockComparer {
    max-width: 280px;
  }
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock .blockComparer {
    max-width: 360px;
  }
}
.mainSite .twoColumnsContentBlock .mediumImage {
  max-width: 520px;
  margin: 0 auto;
}
.mainSite .twoColumnsContentBlock .blockImage {
  width: 100%;
  max-width: 460px;
  display: flex;
  margin-bottom: 24px;
  position: relative;
}
@media (max-width: 460px) {
  .mainSite .twoColumnsContentBlock .blockImage {
    max-width: 280px;
  }
}
@media (max-width: 860px) {
  .mainSite .twoColumnsContentBlock .blockImage {
    max-width: 360px;
  }
}
.mainSite .twoColumnsContentBlock .blockImage img {
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
}
.mainSite .twoColumnsContentBlock .blockImage.typeOverlay {
  position: absolute;
  top: 0;
  left: 0;
}
.mainSite .twoColumnsContentBlock .optionButton {
  margin-right: 8px;
}
.mainSite .fullWidthBlock {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 160px;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock {
    margin-bottom: 120px;
  }
}
.mainSite .fullWidthBlock h1 {
  margin-bottom: 48px;
}
.mainSite .fullWidthBlock h3 {
  margin-bottom: 8px;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock h3 {
    margin-bottom: 24px;
  }
}
.mainSite .fullWidthBlock .subtitle {
  margin-bottom: 120px;
}
.mainSite .fullWidthBlock .pricing {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 80px;
}
.mainSite .fullWidthBlock .twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock .twoColumns {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}
.mainSite .fullWidthBlock .startTrial {
  max-width: 800px;
  margin: 0 auto;
}
.mainSite .fullWidthBlock .startTrial .subtitle {
  margin-bottom: 48px;
}
.mainSite .fullWidthBlock.footer {
  max-width: 1000px;
  margin: 0 auto;
}
.mainSite .fullWidthBlock.footer h1 {
  margin-bottom: 16px;
}
.mainSite .fullWidthBlock.footer .title {
  margin-bottom: 16px;
}
.mainSite .fullWidthBlock.footer .subtitle {
  margin-bottom: 64px;
}
.mainSite .fullWidthBlock.footer .startTrial {
  margin-bottom: 96px;
}
.mainSite .fullWidthBlock.footer .webSecondaryText {
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 240px;
}
.mainSite .fullWidthBlock .pricingBlock {
  padding: 64px 64px 48px 64px;
  border-radius: 40px;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock .pricingBlock {
    padding: 32px 32px 32px 32px;
  }
}
.mainSite .fullWidthBlock .pricingBlock.left {
  grid-column: 1;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock .pricingBlock.left {
    max-height: none;
    grid-column: 1;
    order: 1;
  }
}
.mainSite .fullWidthBlock .pricingBlock.right {
  margin-left: -32px;
  margin-top: -32px;
  grid-column: 2;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock .pricingBlock.right {
    grid-column: 1;
    order: 2;
    margin-left: 0;
    margin-top: 0;
  }
}
.mainSite .fullWidthBlock .pricingBlock.right .price {
  margin-bottom: 64px;
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock .pricingBlock.right .price {
    margin-bottom: 24px;
  }
}
@media (max-width: 860px) {
  .mainSite .fullWidthBlock .pricingBlock.right img {
    margin-bottom: 48px;
  }
}
.mainSite .fullWidthBlock .pricingBlock img {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 280px;
  margin-bottom: 32px;
  text-align: center;
}
.mainSite .fullWidthBlock .pricingBlock .title {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  margin-bottom: 16px;
}
@media (prefers-color-scheme: light) {
  .mainSite .fullWidthBlock .pricingBlock .title {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .fullWidthBlock .pricingBlock .title {
    color: #ffffff;
  }
}
.mainSite .fullWidthBlock .pricingBlock .subtitle {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  margin-bottom: 16px;
}
@media (prefers-color-scheme: light) {
  .mainSite .fullWidthBlock .pricingBlock .subtitle {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .fullWidthBlock .pricingBlock .subtitle {
    color: #a9a9B4;
  }
}
.mainSite .fullWidthBlock .pricingBlock .price {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  margin-bottom: 24px;
}
@media (prefers-color-scheme: light) {
  .mainSite .fullWidthBlock .pricingBlock .price {
    color: #615AB8;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .fullWidthBlock .pricingBlock .price {
    color: #ABA4FF;
  }
}
.mainSite .fullWidthBlock .pricingBlock .btnPrimary,
.mainSite .fullWidthBlock .pricingBlock .btnSecondary {
  height: 56px;
  border-radius: 12px;
  font-size: 16px;
  width: 100%;
}
@media (prefers-color-scheme: light) {
  .mainSite .fullWidthBlock .pricingBlock {
    background-color: #ffffff;
    box-shadow: 0px 12px 17px 1px rgba(0, 0, 0, 0.1);
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .fullWidthBlock .pricingBlock {
    background-color: #26262a;
    box-shadow: 0px 12px 17px 1px rgba(0, 0, 0, 0.1);
  }
}
.mainSite .faqItems .faqItem {
  padding: 48px 64px;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 860px) {
  .mainSite .faqItems .faqItem {
    padding: 32px 16px;
  }
}
@media (prefers-color-scheme: light) {
  .mainSite .faqItems .faqItem {
    border-bottom: 1px solid transparent;
    -o-border-image: linear-gradient(0.25turn, transparent, #e5e5e9, #e5e5e9, transparent);
       border-image: linear-gradient(0.25turn, transparent, #e5e5e9, #e5e5e9, transparent);
    border-image-slice: 1;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .faqItems .faqItem {
    border-bottom: 1px solid transparent;
    -o-border-image: linear-gradient(0.25turn, transparent, #323236, #323236, transparent);
       border-image: linear-gradient(0.25turn, transparent, #323236, #323236, transparent);
    border-image-slice: 1;
  }
}
.mainSite .faqItems .faqItem:last-of-type {
  border: none;
}
.mainSite .faqItems .faqItem .faqTitle {
  text-align: center;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-family: Inter-SemiBold, sans-serif;
  font-size: 20px;
  margin-bottom: 24px;
}
@media (prefers-color-scheme: light) {
  .mainSite .faqItems .faqItem .faqTitle {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .faqItems .faqItem .faqTitle {
    color: #ffffff;
  }
}
.mainSite .faqItems .faqItem .faqDescription {
  text-align: center;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
}
@media (prefers-color-scheme: light) {
  .mainSite .faqItems .faqItem .faqDescription {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .faqItems .faqItem .faqDescription {
    color: #a9a9B4;
  }
}
.mainSite .faqItems .faqItem .faqDescription .highlight {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  line-height: 150%;
  text-decoration: underline;
  font-family: Inter-Bold, sans-serif;
}
@media (prefers-color-scheme: light) {
  .mainSite .faqItems .faqItem .faqDescription .highlight {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .mainSite .faqItems .faqItem .faqDescription .highlight {
    color: #ffffff;
  }
}
.mainSite .faqItems .faqItem p {
  margin: 0 auto;
  margin-bottom: 32px;
  max-width: 600px;
}
.mainSite .faqItems .faqItem p:last-of-type {
  margin-bottom: 0;
}

.licenseOverlay {
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media (prefers-color-scheme: light) {
  .licenseOverlay {
    background-color: #fafafc;
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .licenseOverlay {
    background-color: #19191C;
    color: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .licenseOverlay {
    background-image: url("./images/bgLight.png");
  }
}
@media (prefers-color-scheme: dark) {
  .licenseOverlay {
    background-image: url("./images/bgDark.png");
  }
}
.licenseOverlay .logoBig {
  width: 446px;
  height: 80px;
  margin-bottom: 32px;
}
@media (prefers-color-scheme: light) {
  .licenseOverlay .logoBig #figma {
    fill: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .licenseOverlay .logoBig #figma {
    fill: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .licenseOverlay .logoBig #history {
    fill: url(#history_dark_gradient) #fff;
  }
}
@media (prefers-color-scheme: dark) {
  .licenseOverlay .logoBig #history {
    fill: url(#history_dark_gradient) #fff;
  }
}
.licenseOverlay .mainContent {
  margin-left: 10%;
  margin-bottom: 90px;
}
@media (max-width: 1280px) {
  .licenseOverlay .alignVCenter {
    margin-left: 128px;
  }
}
@media (min-width: 2400px) {
  .licenseOverlay .alignVCenter {
    margin-left: 240px;
  }
}
.licenseOverlay .primaryText {
  font-size: 16px;
  margin-bottom: 12px;
}
.licenseOverlay .options {
  margin-top: 24px;
  margin-bottom: 40px;
}
.licenseOverlay .options button {
  float: left;
  margin-right: 8px;
  width: 200px;
}
.licenseOverlay .btnSecondary {
  min-width: 100px;
}
.licenseOverlay .label {
  width: 50%;
  text-align: left;
  margin-bottom: 8px;
}
.licenseOverlay .linkUrlInput {
  display: flex;
  height: 40px;
  margin-right: 8px;
}
.licenseOverlay .inputForm {
  width: 500px;
  max-width: 1600px;
  margin-top: 32px;
  margin-bottom: 56px;
}
.licenseOverlay .btnActivate {
  width: 170px;
}
.licenseOverlay .btnActivate #indeterminateLoader {
  margin-left: 8px;
}
@media (prefers-color-scheme: light) {
  .licenseOverlay .btnActivate.success {
    background-color: #3f8f5f;
  }
}
@media (prefers-color-scheme: dark) {
  .licenseOverlay .btnActivate.success {
    background-color: #57BA7F;
  }
}

.loader .alignFullCenter {
  bottom: 24px;
}
.loader .dualRingLoader {
  margin-bottom: 16px;
}

.comparer .bigFilesDisclaimer {
  position: absolute;
  bottom: 12px;
  left: 50%;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  padding: 8px 12px;
  border-radius: 6px;
  transform: translate(-50%, 0);
  text-align: center;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.comparer .bigFilesDisclaimer.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .comparer .bigFilesDisclaimer {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bigFilesDisclaimer {
    color: #848494;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .bigFilesDisclaimer {
    background-color: rgba(255, 255, 255, 0.85);
    border: 1px solid #e5e5e9;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bigFilesDisclaimer {
    background-color: rgba(36, 36, 40, 0.85);
    border: 1px solid #323236;
  }
}
.comparer .canvasVersionOverlay {
  display: flex;
  width: 300px;
}
.comparer .canvasVersionOverlay .select {
  width: 100%;
}
.comparer .reactselect__control {
  border-radius: 6px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
@media (prefers-color-scheme: light) {
  .comparer .reactselect__control {
    background-color: rgba(240, 240, 242, 0.8);
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .reactselect__control {
    background-color: rgba(36, 36, 40, 0.8);
  }
}
@media (prefers-color-scheme: light) {
  .comparer .reactselect__control:hover {
    background-color: rgba(234, 234, 236, 0.8);
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .reactselect__control:hover {
    background-color: rgba(53, 53, 60, 0.8);
  }
}
@media (prefers-color-scheme: light) {
  .comparer .reactselect__control svg path {
    fill: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .reactselect__control svg path {
    fill: #848494;
  }
}
.comparer .reactselect__menu {
  border-radius: 6px;
  overflow: hidden;
  margin-top: 2px;
  padding: 4px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
@media (prefers-color-scheme: light) {
  .comparer .reactselect__menu {
    background-color: rgba(250, 250, 252, 0.9);
    border: 1px solid #f0f0f2;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .reactselect__menu {
    background-color: rgba(25, 25, 28, 0.9);
    border: 1px solid #242428;
  }
}
.comparer .reactselect__menu-list {
  padding: 0 4px 0 0;
}
.comparer .reactselect__indicators {
  padding: 0 24px 0 0;
}
.comparer .reactselect__indicators .reactselect__indicator-separator {
  margin-right: 8px;
}
@media (prefers-color-scheme: light) {
  .comparer .compareHandle .__rcs-handle-line {
    background-color: #f0f0f2 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .compareHandle .__rcs-handle-line {
    background-color: #f0f0f2 !important;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .sidebysideHandle .__rcs-handle-line {
    background-color: #fafafc !important;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .sidebysideHandle .__rcs-handle-line {
    background-color: #242428 !important;
  }
}
.comparer .versionContainer {
  padding: 12px 16px;
  transition: all 0.1s ease-out;
}
.comparer .versionContainer img {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}
.comparer .versionContainer .small {
  font-size: 13px;
}
.comparer .versionContainer .dummy-input-wrapper {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}
.comparer .versionOption {
  padding: 12px 16px;
  transition: all 0.1s ease-out;
  border-radius: 2px;
}
@media (prefers-color-scheme: light) {
  .comparer .versionOption:hover, .comparer .versionOption:focus {
    background-color: #eaeaec;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .versionOption:hover, .comparer .versionOption:focus {
    background-color: #35353C;
  }
}
.comparer .versionOption img {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}
.comparer .versionOption .small {
  font-size: 13px;
}
.comparer .comparePadded {
  padding-left: 50%;
}
.comparer .differenceHeader {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
}
@media (prefers-color-scheme: light) {
  .comparer .differenceHeader {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .differenceHeader {
    color: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .differenceHeader {
    background-color: #FFD600;
    color: #19191C;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .differenceHeader {
    background-color: #FFD600;
    color: #19191C;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .differenceOutline {
    border: 2px solid #FFD600;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .differenceOutline {
    border: 2px solid #FFD600;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .differenceArtboard {
    backgroundColor: rgba(0, 0, 0, 0.05);
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .differenceArtboard {
    backgroundColor: rgba(255, 255, 255, 0.05);
  }
}
.comparer .sidePanel {
  width: 240px;
  transition: all 0.3s ease-out;
  padding-top: 24px;
  padding-bottom: 64px;
}
@media (prefers-color-scheme: light) {
  .comparer .sidePanel {
    background-color: #fafafc;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .sidePanel {
    background-color: #242428;
  }
}
.comparer .sidePanel.collapsed {
  width: 0;
  opacity: 0;
}
.comparer .sidePanel .logo {
  padding-left: 24px;
  width: 160px;
  margin-bottom: 32px;
}
.comparer .sidePanel .title {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 32px;
  font-size: 14px;
}
@media (prefers-color-scheme: light) {
  .comparer .sidePanel .title {
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .sidePanel .title {
    color: #ffffff;
  }
}
.comparer .sidePanel .title.hasSubtitle {
  margin-bottom: 4px;
}
.comparer .sidePanel .subtitle {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 32px;
}
.comparer .sidePanel .subtitle.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .comparer .sidePanel .subtitle {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .sidePanel .subtitle {
    color: #848494;
  }
}
.comparer .sidePanel .header {
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
}
.comparer .sidePanel .header.spaced {
  line-height: 20px;
}
@media (prefers-color-scheme: light) {
  .comparer .sidePanel .header {
    color: #868688;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .sidePanel .header {
    color: #848494;
  }
}
.comparer .sidePanel .listItem {
  padding: 8px 24px 10px 24px;
}
.comparer .sidePanel .trialMessage {
  padding: 24px;
}
.comparer .sidePanel .trialMessage .secondaryText {
  margin-bottom: 16px;
}
.comparer .sidePanel .trialMessage .secondaryText::first-letter {
  text-transform: capitalize;
}
.comparer .sidePanel .trialMessage .btnPrimary {
  width: 100%;
  margin-bottom: 16px;
}
.comparer .sidePanel .trialMessage .btnSecondary {
  width: 100%;
}
.comparer .bottomBar {
  padding: 12px 12px 12px 12px;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}
@media (prefers-color-scheme: light) {
  .comparer .bottomBar {
    background-color: #ffffff;
    border-top: 1px solid #f4f4f6;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bottomBar {
    background-color: #19191C;
    border-top: 1px solid #242428;
  }
}
.comparer .bottomBar .btnSecondary {
  width: 100px;
  height: 36px;
}
.comparer .bottomBar .btnSecondary.viewOption {
  margin-right: 8px;
}
.comparer .bottomBar .btnSecondary.iconButton {
  width: 36px;
  margin-right: 8px;
}
@media (prefers-color-scheme: light) {
  .comparer .bottomBar .btnSecondary.iconButton path {
    fill: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bottomBar .btnSecondary.iconButton path {
    fill: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .bottomBar .btnSecondary.iconButton:hover {
    border-color: #797979;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bottomBar .btnSecondary.iconButton:hover {
    border-color: #797979;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .bottomBar .btnSecondary.iconButton.checked {
    border-color: #901AED;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bottomBar .btnSecondary.iconButton.checked {
    border-color: #901AED;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .bottomBar .btnSecondary.iconButton.checked:hover {
    border-color: #ad51f8;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bottomBar .btnSecondary.iconButton.checked:hover {
    border-color: #ad51f8;
  }
}
@media (prefers-color-scheme: light) {
  .comparer .bottomBar .btnSecondary.iconButton.checked path {
    fill: #901AED;
  }
}
@media (prefers-color-scheme: dark) {
  .comparer .bottomBar .btnSecondary.iconButton.checked path {
    fill: #ffffff;
  }
}
.comparer .bottomBar span {
  margin-right: 8px;
}
.comparer .bottomBar .leftElements {
  grid-column: 1;
}
@media (max-width: 1280px) {
  .comparer .bottomBar .leftElements {
    display: none;
  }
}
.comparer .bottomBar .centerElements {
  grid-column: 2;
}
.comparer .bottomBar .rightElements {
  grid-column: 3;
  justify-content: flex-end;
}
@media (max-width: 1280px) {
  .comparer .bottomBar .rightElements {
    display: none;
  }
}

.productHuntBadge {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 250px;
  height: 54px;
}
@media (max-width: 860px) {
  .productHuntBadge {
    display: none;
  }
}

@font-face {
  font-family: AvenirNextLTPro-Medium;
  src: url(./fonts/AvenirNextLTPro-Medium.ttf) format("truetype");
}
@font-face {
  font-family: AvenirNextLTPro-Bold;
  src: url(./fonts/AvenirNextLTPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: AvenirNextLTPro-Demi;
  src: url(./fonts/AvenirNextLTPro-Demi.ttf) format("truetype");
}
@font-face {
  font-family: Inter-Medium;
  src: url(./fonts/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: Inter-SemiBold;
  src: url(./fonts/Inter-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: Inter-Bold;
  src: url(./fonts/Inter-Bold.ttf) format("truetype");
}
html {
  box-sizing: border-box;
  background: transparent;
  overflow: hidden;
  cursor: default;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: AvenirNextLTPro-Medium, -apple-system, BlinkMacSystemFont, sans-serif;
}
@media (prefers-color-scheme: light) {
  html,
  body {
    background-color: #fafafc;
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  html,
  body {
    background-color: #19191C;
    color: #ffffff;
  }
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

@media (prefers-color-scheme: light) {
  .innerCanvas {
    background-color: #fafafc;
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .innerCanvas {
    background-color: #19191C;
    color: #ffffff;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  /* Prevent the content from being selectionable */
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.dotted {
  background-size: 20px 20px;
  background-position: -19px -19px;
}
@media (prefers-color-scheme: light) {
  .dotted {
    background-color: #ffffff;
    color: #323232;
  }
}
@media (prefers-color-scheme: dark) {
  .dotted {
    background-color: #19191C;
    color: #ffffff;
  }
}
@media (prefers-color-scheme: light) {
  .dotted {
    background-image: radial-gradient(rgba(50, 50, 50, 0.1) 1px, transparent 0);
  }
}
@media (prefers-color-scheme: dark) {
  .dotted {
    background-image: radial-gradient(rgba(255, 255, 255, 0.07) 1px, transparent 0);
  }
}

.animatedDiv.visible {
  transition: visibility 0s, opacity 0s;
  visibility: visible;
  opacity: 1;
}
.animatedDiv.invisible {
  transition: visibility 0s, opacity 0s;
  visibility: hidden;
  opacity: 0;
}
.animatedDiv.fadeOut {
  transition: opacity 0.5s linear, visibility 0s 0.5s !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
.animatedDiv.fadeOut.fast {
  transition: opacity 0.2s linear, visibility 0s 0.2s !important;
}
.animatedDiv.fadeIn {
  transition: visibility 0s, opacity 0.5s linear !important;
  opacity: 1 !important;
  visibility: visible !important;
}
.animatedDiv.fadeIn.fast {
  transition: visibility 0s, opacity 0.2s linear !important;
}

a {
  color: inherit;
}/*# sourceMappingURL=App.css.map */